
<section class="main-container">
  <div fxLayout="row" fxLayoutAlign="end start">
    <button mat-raised-button (click)="addTask()">Add Shlok</button>
  </div>

  <section class="table-sec mat-elevation-z8">
    <h1>Shlok List</h1>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="day">
        <th mat-header-cell *matHeaderCellDef width="110"> Pregnancy Day</th>
        <td mat-cell *matCellDef="let element" width="110"> {{element.pregnancyDay}}</td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="shlok">
        <th mat-header-cell *matHeaderCellDef> Shlok </th>
        <td mat-cell *matCellDef="let element"> {{element.shlok}} </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef>Edit</th>
        <td mat-cell *matCellDef="let element"><mat-icon (click)="onEdit(element.id)">edit</mat-icon></td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>Delete</th>
        <td mat-cell *matCellDef="let element"> <mat-icon class="del-icon" (click)="onDelete(element.id)">delete_outline</mat-icon> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20,30,40,50]"
    showFirstLastButtons
     >
  </mat-paginator>
  </section>
</section>
