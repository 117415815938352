<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<html>
    <head>
        <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500" rel="stylesheet">
    </head>
<body>

    <mat-progress-bar mode="indeterminate" color="warn"  *ngIf="ppdisp"></mat-progress-bar>

        <div  id="ex" class="margin" >
            <br>
            <br>
            <br>
            <br>
            <mat-card-content>
                
            <tr>
                <td>
                    
                    <div class="row" id="outside">
                        
                        <mat-card class="example-card">
                            <img width="350px" style="margin-left: 10%;" src="https://estrospa.sirv.com/splashlogo.png" />
                        </mat-card>
                    </div>
                </td>
                <td>
                    <!-- <img src="image1.gif"> -->
                    <form class="my-form" >
                    <div class="row" id="inside">
                    <mat-card class="login">
                        <mat-card-title id="hellotop"> Login</mat-card-title>
                        <pre id="cen">Login To Admin Portal</pre>
                    
                        <p>
                            <mat-form-field appearance="outline" class="input">
                            <mat-label>Email</mat-label>
                            <input matInput name="email" placeholder="xyz@gmail.com" [(ngModel)]="email" >
                            <mat-icon matSuffix>email</mat-icon>
                            
                            
                            </mat-form-field>
                            <br>
                            <mat-form-field appearance="outline" class="input">
                                
                                <mat-label>Password</mat-label>
                                <input type="password" matInput name="password" placeholder="$#@!" [(ngModel)]="password">
                                <mat-icon matSuffix>lock</mat-icon>
                            </mat-form-field>
                        </p>
                        <button mat-fab color="primary" class="buttonui" type="submit" (click)="login()"> 
                            <mat-icon matSuffix>arrow_forward</mat-icon>
                        </button>
                        <br>
                        <br>
                         <br><br><br>
                        <pre id="cen"><mat-hint>Forgot Password?<a style="color: white;" (click)="forgot()"><u>Click here</u> </a></mat-hint></pre>                
                       
                    
                    </mat-card>
                </div>
            </form>
            </td>
        </tr>
    
    </mat-card-content>
                <!-- <mat-card-header>
                
                
                <mat-card-subtitle>Dog Breed</mat-card-subtitle>
                </mat-card-header>
                
                <mat-card-content>
                <p>
                    The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
                    
                    A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
                    bred for hunting.
                </p>
                </mat-card-content>
                <mat-card-actions>
                <button mat-button>LIKE</button>
                <button mat-button>SHARE</button>
                </mat-card-actions> -->
                <br>
                <br>
                <br><br><br><br>
           
        
        </div>

    
</body>
</html>
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->



