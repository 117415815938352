<section>
    <h1>{{editMode ? "Update Task" : "Add Task"}}</h1>
  <form [formGroup]="taskForm" novalidate>
    <div fxLayout="column">
      <mat-form-field appearance="outline"  fxFlex="100">
        <mat-label>Task</mat-label>
        <input matInput placeholder="Enter Task" formControlName="newTask">
      </mat-form-field>
      <mat-error class="err" *ngIf="taskForm.get('newTask').hasError('required') && taskForm.get('newTask').touched">
        New task is required
      </mat-error>
    </div>

    <div fxLayout="column">
      <mat-form-field appearance="outline" fxFlex="100" >
        <mat-label>Enter Pregnancy Day</mat-label>
        <input matInput type="number" placeholder="Enter Pregnancy Day" formControlName="pregnancyDay" >
      </mat-form-field>
      <mat-error class="err"
        *ngIf="taskForm.get('pregnancyDay').hasError('required') && taskForm.get('pregnancyDay').touched">
        Enter Pregnancy Day
      </mat-error>
    </div>

    <div fxFlayout="row" fxLayoutAlign="end center">
      <button mat-button class="sub-btn" (click)="onCancel()">Cancel</button>
      <button mat-button class="sub-btn" color="primary" *ngIf="!editMode"  (click)="onSubmit()" [disabled]="taskForm.invalid">Submit</button>
      <button mat-button class="sub-btn" color="primary" *ngIf="editMode" (click)="onUpdate()" [disabled]="taskForm.invalid">Update</button>
    </div>
  </form>
</section>
