<div class="container">
  <div class="item" *ngFor="let member of files1">
    <mat-card class="example-card">
      <mat-card-content>
        <div class="centered">
        </div>
        <mat-form-field appearance="outline" class="input">
          <mat-label>User Name</mat-label>
          <input matInput name="name" style="color: white" placeholder="Chapter Name" [(ngModel)]="member.name"
            disabled>
        </mat-form-field>
        <mat-form-field appearance="outline" class="input">
          <mat-label>Email</mat-label>
          <textarea matInput name="url" rows="7" style="color: white" placeholder="Select the url from above"
            [(ngModel)]="member.email" disabled></textarea>
        </mat-form-field>
        <mat-form-field appearance="outline" class="input">
          <mat-label>Query</mat-label>
          <textarea matInput name="url" rows="7" style="color: white" placeholder="Admin Answer"
            [(ngModel)]="member.messgae" disabled></textarea>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
