<div class="container">
  <div class="item" *ngFor="let member of files1">
    <mat-card class="example-card">
      <mat-card-content>
        <div class="centered">
        </div>
        <mat-form-field appearance="outline" class="input">
          <mat-label>User Name</mat-label>
          <input matInput name="name" style="color: white" placeholder="Chapter Name" [(ngModel)]="member.name"
            disabled>
        </mat-form-field>
        <mat-form-field appearance="outline" class="input">
          <mat-label>Question</mat-label>
          <textarea matInput name="url" rows="7" style="color: white" placeholder="Select the url from above"
            [(ngModel)]="member.question" disabled></textarea>
        </mat-form-field>
        <mat-form-field appearance="outline" class="input">
          <mat-label>Admin Answer</mat-label>
          <textarea matInput name="url" rows="7" style="color: white" placeholder="Admin Answer"
            [(ngModel)]="member.answer"></textarea>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <div class="centered">
          <section class="cards item">
            <article class="card">
              <button style="width:100%;" mat-raised-button color="primary" style="color:royalblue" type="submit"
                (click)="update(member.name,member.question,member.answer,member.id)">{{member.Status}} Answer</button>
            </article><!-- /card-one -->
            <article class="card">
              <button style="width:100%;" mat-raised-button color="warn" type="submit"
                (click)="delete(member.name,member.question,member.answer,member.id)">Delete Question</button>
            </article><!-- /card-two -->
          </section>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
